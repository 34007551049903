<template>
  <nuxt-page/>
</template>
<script setup>
const title = "BNTO - Clothing Rental Subscription"
const description = "⭐Fashion On Rotation⭐Clothing Rental Subscription⭐6 items, $98 a month"
useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
  ogImage: '/og-image.png',
  twitterCard: 'summary_large_image',
})
</script>
